<app-header></app-header>

<div class="pageContent">
  <mat-card class="tablaListadoContratos">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>{{dataSource[0].CodigoContrato}}</div>
                  <span title="dataSource[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource[0].ContratoSituacion">
                  {{dataSource[0].ContratoSituacion}} 
                  <i>-</i>
                  <span>{{dataSource[0].Tarifa}}</span>
                </td>
                <td>
                  <span title="dataSource[0].Entorno">
                    <i class="fal fa-lightbulb" *ngIf="dataSource[0].Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="dataSource[0].Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="dataSource[0].NombreCliente">{{dataSource[0].Tarifa}}</td>
                <td title="dataSource[0].NombreCliente"><span>{{dataSource[0].NombreCliente}}</span></td>
                <td title="{{dataSource[0].DireccionSuministro}}"><span>{{dataSource[0].DireccionSuministro}}</span></td>
                <td>
                  <input type="button" class="btn btnStyle_1" value="Detalles" (click)="irDetallesContrato()">
                </td>
                <td>
                  <div>
                    <i class="fal fa-angle-down"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel-header>
        <div id="tablaAcordeon" *ngIf="ultimosContratos.length > 1">

          <input type="text" matInput name="filtroTabla" [(ngModel)]="filtroTabla" placeholder="Buscar en la tabla de registros">
          
          <table>
            <thead>
              <tr>
                <th>{{'Contrato' | translate}} Nº</th>
                <th>{{'Situación' | translate}}</th>
                <th>{{'Tipo' | translate}}</th>
                <th>{{'Tarifa' | translate}}</th>
                <th>{{'Cliente' | translate}}</th>
                <th>{{'Dirección' | translate}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let contrato of ultimosContratos | contratosFilter:filtroTabla:ultimosContratosAUX" (click)="filtraListadoContratos(contrato.IdContrato, true)">
              <tr [ngClass]="{'rowActive':contrato.CodigoContrato == dataSource[0].CodigoContrato}" *ngIf="contrato.CodigoContrato != dataSource[0].CodigoContrato"> 
                <td>
                  <div >{{contrato.CodigoContrato}}</div>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td>
                  {{contrato.ContratoSituacion}}
                  <i>-</i>
                  <span>{{contrato.Tarifa}}</span>
                </td>
                <td>
                  <span title="contrato.Entorno">
                    <i class="fal fa-lightbulb" *ngIf="contrato.Entorno == 'Luz'"></i>
                    <i class="fal fa-fire" *ngIf="contrato.Entorno == 'Gas'"></i>
                  </span>
                </td>
                <td title="contrato.NombreCliente">{{contrato.Tarifa}}</td>
                <td title="contrato.NombreCliente">{{contrato.NombreCliente}}</td>
                <td title="{{contrato.DireccionSuministro}}">{{contrato.DireccionSuministro}}</td>
              </tr>
            </tbody>
          </table>
        </div>
         
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>

  <mat-accordion>
    <mat-expansion-panel class="acordeon">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <p><b>¿No consumes lo que has recargado?</b></p>
            <p><b>¿Quieres otra tarifa que se ajuste más a tus preferencias?</b></p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          Cambia tu tarifa
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Contenido del desplegable -->
      <div class="contenedorCambios">
        <mat-card class="cardsContents cardCambioTarifa__1">
          <h2 class="tituloCards">Cambia tu tarifa</h2>

          <article>
            <div *ngFor="let prod of productosList">
              <input style="display: none;" type="radio" name="seleccionProducto" id="{{prod.IdPromocionTarifaGrupo}}" value="{{prod.IdPromocionTarifaGrupo}}" 
              (input)="controlTarifas(prod.IdPromocionTarifaGrupo, true);forzarRegularizacion('Bimensual',prod.IdPromocionTarifaGrupo)">
              <label for="{{prod.IdPromocionTarifaGrupo}}">
                <section id="productListCabecera">
                  <h4>{{prod.TextoPromocionTarifaGrupo}} </h4>
                  <h3>{{prod.SegundoTextoPromocionTarifaGrupo}}</h3>
                </section>

                <section id="productListBody">
                  <span [innerHtml]="prod.DescripcionPromocion"></span>
                </section>
                <!-- <p><button class="btn btnStyle_2">Contratar</button></p> -->
              </label>
              <!-- HAn solicitado ocultarlo
              <p [hidden]="prod.IdPromocionTarifaGrupo != dataSource[0].IdPromocionTarifaGrupo" style="text-align: center;">Tarifa contratada actualmente</p> -->
            </div>
            <h3 *ngIf="productosList.length == 0" style="text-align: center;width: 100%;">
              ¡Ups! Parece que no se han podido cargar las tarifas. Si el error persiste escríbenos a 
              <a href="mailto:clientes@wekiwi.es" style="text-decoration: underline;" >clientes@wekiwi.es</a>
            </h3>
          </article>
        </mat-card>

        <mat-card class="cardCambioTarifa cardsContents" [ngClass]="{'ocultarMatCard': !IsPrecarga}">
          <ul id="listaIconosCambioPeriodicidad">
            <li><i class="fas fa-question"></i> ¿Sabías que puedes elegir cuánto quieres gastar en invierno y en verano?</li>
            <li><i class="fas fa-question"></i> ¿Por qué pagar lo mismo cuando consumes menos?</li>
            <li><i class="fas fa-question"></i> ¿Sabías que puedes elegir la periodicidad de tu recara? Puedes
              adeltantar hasta tres meses, obteniendo mayores descuentos.</li>
          </ul>

          <div class="contenedorCambioTarifa">
            <mat-card class="cardsContents cardMinCambioTarifa" style="border: 1px solid gainsboro;">
              <h2 class="tituloCards">Periodicidad</h2>

              <article class="radio-toolbar">
                <div class="radioChilds">
                  <input type="radio" id="mensual" name="periodicidad" value="Mensual" (input)="controlTarifas('Mensual', false)" [disabled]="Servicio == 'GAS'">
                  <section id="circ3"></section>
                  <label for="mensual">Mensual</label>
                </div>

                <div class="radioChilds">
                    <input type="radio" id="bimestral" name="periodicidad" value="Bimensual" (input)="controlTarifas('Bimensual', false)">
                    <section id="circ2"></section>
                    <label for="bimestral">Bimestral</label>
                  </div>

                  <div class="radioChilds">
                    <input type="radio" id="trimestral" name="periodicidad" value="Trimestral" (input)="controlTarifas('Trimestral', false)" [disabled]="Servicio == 'GAS'">
                    <section id="circ1"></section>
                    <label for="trimestral">Trimestral</label>
                  </div>

                  <!-- <div class="radioChilds" >
                    <input type="radio" id="cuatrimestral" name="periodicidad" value="Cuatrimestral" (input)="controlTarifas('Cuatrimestral', false)" [disabled]="Servicio == 'LUZ'">
                    <section id="circ0"></section>
                    <label for="cuatrimestral">Cuatrimestral</label>
                  </div> -->
                
                <br><br>

              </article>
            </mat-card>

            <mat-card class="cardsContents cardMaxCambioTarifa">
              <div>
                <span><i class="far fa-lightbulb"></i> <b>Recuerda que, a mayor periodo de recarga, más barata te saldrá la energía. Esto significa que, si tu recarga es trimestral, recibirás más kWh por el mismo precio.</b></span>
                <span><i class="far fa-lightbulb"></i> <b>Al elegir recarga mensual, todos los meses se te hará un cargo con la cantidad elegida y a final de mes, se te regularizará con tu gasto real. Si has consumido más que la recarga efectuada, simplemente se te cargará esa diferencia en tu cuenta. Por el contrario, si has consumido menos, esa cantidad se te devolverá íntegramente.</b></span>
                <span><i class="far fa-lightbulb"></i> <b>En los periodos bimestrales y trimestrales, la recarga se hará cada 2 o 3 meses, y la regularización se hará cada mes.</b></span>
              </div>
            </mat-card>
          </div>

          <div class="blurProductoPrecarga" *ngIf="!IsPrecarga">
            <div id="blurBackGround"></div>
    
            <div id="textBlur">
              <span style="color: #fff523">I</span> <span style="color: #6f49ce;">N</span> <span style="color: #fff523">F</span> <span style="color: #6f49ce;">Ó</span> <span style="color: #fff523">R</span> <span style="color: #6f49ce;">M</span> <span style="color: #fff523">A</span> <span style="color: #6f49ce;">T</span> <span style="color: #fff523">E</span> <span style="color: #6f49ce;">&nbsp;</span> <span style="color: #fff523;">&nbsp;</span> <span style="color: #6f49ce;">A</span> <span style="color: #fff523">Q</span> <span style="color: #6f49ce;">U</span> <span style="color: #fff523">Í</span>
            </div>
          </div>
        </mat-card>

        <mat-card class="cardsContents cardCambioTarifa" [ngClass]="{'ocultarMatCard': !IsPrecarga}">

          <h2 class="tituloCards">Recarga actual</h2>

          <div class="contenedorCambioTarifa">

            <article class="cardMinCambioTarifa" style="border: none;">
              <!-- <mat-select placeholder="Cambiar recarga actual" [(ngModel)]="cambiarRecargaDistintaModel">
                <mat-option [value]="1">Cambiar recarga actual</mat-option>
              </mat-select> -->

              <mat-card style="height: calc(100% - 145px);box-shadow: none !important;">
                <section id="recagraActual">
                  <article>{{listadoRangoRecargas[rangoRecarga]}} €</article>

                  <input type="range" #rangeRecarga [(ngModel)]="rangoRecarga" min="0" max="{{listadoRangoRecargas.length - 1}}" step="1" class="slider" id="myRange" (change)="actualizarListadoRecargas(listadoRangoRecargas[rangoRecarga]);comprobarCambioRecarga([], false)">

                  <ul>
                    <li *ngFor="let obrp of listadoRangoRecargas" [ngClass]="{'listActiveRecarga':obrp == rangoRecarga}">
                      <span matTooltip="Esta es tu recarga actual" matTooltipPosition="below" *ngIf="obrp == dataSource[0].RecargaActual" style="color: black;">{{obrp}}€ <i style="color: black;" class="far fa-info-circle"></i></span>
                      <span *ngIf="obrp != dataSource[0].RecargaActual">{{obrp}}€</span>
                    </li>
                  </ul>
                </section>

              </mat-card>
            </article>

            <article class="recargaDistinta">
              <!-- <mat-select placeholder="Distinta recarga mensual" [(ngModel)]="recargaDistintaModel">
                <mat-option [value]="1">Recarga Distinta todos los meses</mat-option>
              </mat-select> -->

              <mat-card class="cardsContents">
                <section>
                  <div id="rangoRecarga" *ngFor="let garf of datosGrafico; let i = index">
                    <mat-select placeholder="Recarga" [ngClass]="{'selectDisabled': garf.disabled}" [ngStyle]="{'visibility': garf.ocultar ? 'hidden' : 'visible' }"
                     [(ngModel)]="garf.cuotas" [disabled]="garf.disabled" (selectionChange)="comprobarCambioRecarga(garf, true)">
                      <mat-option [value]="recarga" *ngFor="let recarga of listadoRangoRecargas">{{recarga}} €</mat-option>
                    </mat-select>
                    <p style="white-space: nowrap; width: 75px;">{{garf.mesesShort}}</p>  
                  </div>
                </section>
                <highcharts-chart [Highcharts]="Highcharts" class="grafico" [(options)]="charRecargaDistinta"></highcharts-chart>
              </mat-card>
            </article>
          </div>

          <div class="blurProductoPrecarga" *ngIf="!IsPrecarga">
            <div id="blurBackGround"></div>
    
            <div id="textBlur">
              <span style="color: #fff523">I</span> <span style="color: #6f49ce;">N</span> <span style="color: #fff523">F</span> <span style="color: #6f49ce;">Ó</span> <span style="color: #fff523">R</span> <span style="color: #6f49ce;">M</span> <span style="color: #fff523">A</span> <span style="color: #6f49ce;">T</span> <span style="color: #fff523">E</span> <span style="color: #6f49ce;">&nbsp;</span> <span style="color: #fff523;">&nbsp;</span> <span style="color: #6f49ce;">A</span> <span style="color: #fff523">Q</span> <span style="color: #6f49ce;">U</span> <span style="color: #fff523">Í</span>
            </div>
          </div>
        </mat-card>
        <div class="blurProductoPrecarga" *ngIf="isLoading">
          <div id="blurBackGround"></div>
          
          <div>
            <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
          </div>
        </div>
       
        <div style="float:right">
          <button class="btn btnStyle_2" (click)="guardarInfo('Tarifa');" [disabled]="(dataSource[0]['IdPromocionTarifaGrupo'] == tarifaGrupoSeleccionado.IdPromocionTarifaGrupo) && (!tarifaGrupoSeleccionado.IsPrecarga || dataSource[0].Periodicidad == mensualidadPeriodicidad) && (bloquearAltaCambioRecarga)">Guardar cambios <i class="fas fa-pencil"></i></button>
        </div>
      </div>


    </mat-expansion-panel>

    <mat-expansion-panel class="acordeon" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p><b>¿Has cambiado tu teléfono o dirección de email?</b></p>
        </mat-panel-title>
        <mat-panel-description>
          Cambia tus datos personales
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="contenedorCambios">

        <div class="cardDatosPersonales">
          <h2 class="tituloCards">Datos personales</h2>

          <article>
            <table>
              <tr>
                <td>Nombre</td>
                <td *ngIf="!editarDatosPersonales"><b>{{NombreCliente}}</b></td>
                <td *ngIf="editarDatosPersonales">
                  <div style="position: relative;">
                    <input type="text" [(ngModel)]="NombreCliente">
                    <span class="focus-borde">
                      <i></i>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Primer apellido</td>
                <td *ngIf="!editarDatosPersonales"><b>{{Apellido1Cliente}}</b></td>
                <td *ngIf="editarDatosPersonales">
                  <div style="position: relative;">
                    <input type="text" [(ngModel)]="Apellido1Cliente">
                    <span class="focus-borde">
                      <i></i>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Segundo apellido</td>
                <td *ngIf="!editarDatosPersonales"><b>{{Apellido2Cliente}}</b></td>
                <td *ngIf="editarDatosPersonales">
                  <div style="position: relative;">
                    <input type="text" [(ngModel)]="Apellido2Cliente">
                    <span class="focus-borde">
                      <i></i>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td *ngIf="!editarDatosPersonales"><b>{{Email}}</b></td>
                <td *ngIf="editarDatosPersonales">
                  <div style="position: relative;">
                    <input type="text" [(ngModel)]="Email">
                    <span class="focus-borde">
                      <i></i>
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Teléfono</td>
                <td *ngIf="!editarDatosPersonales"><b>{{Tlf}}</b></td>
                <td *ngIf="editarDatosPersonales">
                  <div style="position: relative;">
                    <input type="text" [(ngModel)]="Tlf">
                    <span class="focus-borde">
                      <i></i>
                    </span>
                  </div>
                </td>
              </tr>
              <!-- <tr>
                <td>Fecha de nacimiento</td>
                <td *ngIf="!editarDatosPersonales"><b>14/05/1990</b></td>
                <td *ngIf="editarDatosPersonales">
                  <div style="position: relative;">
                    <input type="text">
                    <span class="focus-borde">
                      <i></i>
                    </span>
                  </div>
                </td>
              </tr> -->
            </table>

            <div style="display: flex; gap: 20px;">
              <button class="btn btnStyle_2" *ngIf="!editarDatosPersonales" (click)="editarDatosPersonales = true;">Modificar <i class="fas fa-pencil"></i></button> <!--Añadir fecha de nacimiento si esta se acaba poniendo en la condicion del disabled :D:D:D:D-->
              <button class="btn btnStyle_2" *ngIf="editarDatosPersonales" (click)="editarDatosPersonales = false;cargarDatos()">Cancelar <i class="fas fa-times"></i></button> 
              <button class="btn btnStyle_2" *ngIf="editarDatosPersonales" (click)="editarDatosPersonales = false;guardarInfo('DatosPersonales');" [disabled]="!NombreCliente || !Apellido1Cliente || !Apellido2Cliente || !Email || !Tlf">Guardar <i class="fas fa-pencil"></i></button>
            </div>

          </article>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="acordeon">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <p><b>¿Quieres que otra persona sea el titular?</b></p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          Cambia el titular
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="contenedorCambios">
        <div style="padding: 0 20px 20px 20px;">
          <p id="textoExplicacion" style="margin: 0 10px;">
            Si quieres cambiar de titular de tu punto de suministro, rellena este formulario con los datos del nuevo
            tiutlar. Nosotros nos encargamos del resto
          </p>
        </div>

        <hr>

        <mat-card class="cardsContents cardCambioTIutlar__1">
          <ul>
            <li class="listaCambioTarifa">
              <div>
                <h1>1</h1>
                <span></span>
              </div>
              <p>Rellena los datos de contrato del nuevo titular.</p>
            </li>
            <li class="listaCambioTarifa">
              <div>
                <span></span>
                <h1>2</h1>
                <span></span>
              </div>
              <p>Nos pondremos en contacto via email con la persona.</p>
            </li>
            <li class="listaCambioTarifa">
              <div>
                <span></span>
                <h1>3</h1>
                <span></span>
              </div>
              <p>Recibirás un email cuando el nuevo titular valide sus datos y el contrato.</p>
            </li>
            <li class="listaCambioTarifa">
              <div>
                <span></span>
                <h1>4</h1>
                <span></span>
              </div>
              <p>Efectuaremos el cambio con la fecha y lectura que tú nos has indicado.<br>El proceso puede
                tardar entre 4 y 20 dias naturales.</p>
            </li>
            <li class="listaCambioTarifa">
              <div>
                <span></span>
                <h1>5</h1>
                <span></span>
              </div>
              <p>Te envíaremos un email cuando el cambio sea 100% efectivo.<br> En ese momento dejarás de
                ser el titular.</p>
            </li>
          </ul>
        </mat-card>

        <hr>
        
        <mat-card class="cardsContents cardCambioTIutlar__2">
          <h2 class="tituloCards">Datos de contacto del nuevo titular</h2>

          <p>
            Necesitamos estos datos para poder contactar con la persona que será a partir de ahora el nuevo titular del
            contrato
          </p>

          <label for="nombreNuevoTitular">Nombre*</label><br>
          <input type="text" id="nombreNuevoTitular" [(ngModel)]="newNameTitular">

          <br><br>

          <label for="emailNuevoTitular">Email*</label><br>
          <input type="text" id="emailNuevoTitular" [(ngModel)]="newEmailTitular">

          <br><br>
        </mat-card>

        <hr>

        <mat-card class="cardsContents cardCambioTIutlar__3">

          <h2 class="tituloCards">Datos del cambio</h2>

          <p>
            Escoge en qué fecha quieres dejar de ser el titular y la última lectura para finalizar
          </p>

          <span>
            <input type="radio" name="fechaLectura" value="fecha y lectura de la última factura" [(ngModel)]="newLecturaTitular">
            <b>Fecha y lectura de última factura</b>
          </span>

          <br><br>

          <span>
            <input type="radio" name="fechaLectura" value="fecha y lectura personalizadas" [(ngModel)]="newLecturaTitular">
            <b>Fecha y lectura personalizadas</b>
          </span>

          <br><br>

          <mat-form-field appearance="fill" *ngIf="newLecturaTitular == 'fecha y lectura personalizadas'">
            <mat-label>Escoge una fecha</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="newFechaLecturaTitular">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

        </mat-card>

        <button class="btn btnStyle_2" style="float: right;" (click)="guardarInfo('cambioTitular');">Enviar <i class="fas fa-pencil"></i></button>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="acordeon">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <p><b>¿Quieres cambiar la cuenta donde van dirigidos los cargos?</b></p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          Cambia tus datos bancarios
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="contenedorCambios">
        <div class="cardCambioBancario">

          <h2 class="tituloCards">Datos bancarios</h2>

          <table>
            <tr>
              <td>Nombre titular</td>
              <td *ngIf="!editarDatosBancarios"><b>{{nombrePagador}}</b></td>
              <td *ngIf="editarDatosBancarios">
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="nombrePagador">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>IBAN</td>
              <td *ngIf="!editarDatosBancarios"><b>{{IBAN}}</b></td>
              <td *ngIf="editarDatosBancarios">
                <div style="position: relative;">
                  <input type="text" [(ngModel)]="IBAN">
                  <span class="focus-borde">
                    <i></i>
                  </span>
                </div>
              </td>
            </tr>
          </table>

          <article>
            <p id="textoExplicacion">
              Ten en cuenta que... <br> El cambio se hará efectivo en el siguiente periodo de facturación que no esté en
              curso
            </p>
           
            <div style="display: flex; gap: 20px;">
              <button class="btn btnStyle_2" *ngIf="!editarDatosBancarios" (click)="!editarDatosBancarios = true">Modificar <i class="fas fa-pencil"></i></button>
              <button class="btn btnStyle_2" *ngIf="editarDatosBancarios" (click)="editarDatosBancarios = false;cargarDatos()">Cancelar <i class="fas fa-times"></i></button> 
              <button class="btn btnStyle_2" *ngIf="editarDatosBancarios" (click)="editarDatosBancarios = false;guardarInfo('DatosBancarios');" [disabled]="!nombrePagador || !IBAN">Guardar <i class="fas fa-pencil"></i></button>
            </div>
          </article>
        </div>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel class="acordeon">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <!-- <p><b>¿Quieres energía verde?</b></p> -->
            <p><b>¿Aún no cuentas con tu propio árbol?</b></p>
            <p><b>¿Quieres que el planeta viva sin plásticos?</b></p>
          </div>
        </mat-panel-title>
        <mat-panel-description>
          Cambia el mundo
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="contenedorCambios">
        <div class="cardCambioMundo">
          <!-- <mat-card>
            <h2 class="tituloCards">Energía verde</h2>

            <article>
              <img src="assets/img/energiaVerdeIcon.png" width="80px">

              <div>
                <input type="radio" id="usoEnergiaVerde" name="energiaVerde" [value]="true"  #usoEnergiaVerde [(ngModel)]="IsEnergiaVerde">
                <label for="usoEnergiaVerde">Uso energía verde</label>

                <input type="radio" id="noEnergiaVerde" name="energiaVerde" [value]="false" #noEnergiaVerde [(ngModel)]="IsEnergiaVerde">
                <label for="noEnergiaVerde">No uso energía verde</label>
              </div>
            </article>

            <br>
            <div class="footerCambiarMundo">
              <p *ngIf="!dataSource[0].IsEnergiaVerde">*Colabora con el proyeto por solo {{dataSource[0].ImporteEnergiaVerde}}€ al día</p>
              <p *ngIf="dataSource[0].IsEnergiaVerde"></p>
              <button class="btn btnStyle_1" style="float:right" (click)="guardarInfo('EnergiaVerde')" [disabled]="IsEnergiaVerde == dataSource[0].IsEnergiaVerde">Cambiar</button>
            </div>
          </mat-card> -->

          <mat-card>
            <h2 class="tituloCards">Treedom</h2>

            <article>
              <img src="assets/img/iconoArbolArbol.png" width="55px">

              <div>
                <input type="radio" id="usoColaboro" name="colaborar" [value]="true" #usoColaboro [(ngModel)]="IsTreedom">
                <label for="usoColaboro">Colaboro</label>

                <input type="radio" id="noColaboro" name="colaborar" [value]="false" #noColaboro [(ngModel)]="IsTreedom">
                <label for="noColaboro">No colaboro</label>
              </div>
            </article>

            <br>

            <div class="footerCambiarMundo">
              <p *ngIf="!dataSource[0].IsTreedom">*Colabora con el proyeto por solo {{dataSource[0].ImporteTreedom}}€ al día</p>
              <p *ngIf="dataSource[0].IsTreedom"></p>
              <button class="btn btnStyle_1" (click)="guardarInfo('Treedom')" [disabled]="IsTreedom == dataSource[0].IsTreedom">Cambiar</button>
            </div>
          </mat-card>

          <mat-card>
            <h2 class="tituloCards">Plastic Bank</h2>

            <article>
              <img src="assets/img/plasticbank.PNG" width="180px">

              <div>
                <input type="radio" id="usoColaboroB" name="colaborarBank" [value]="true" #usoColaboroB [(ngModel)]="IsPlasticBank">
                <label for="usoColaboroB">Colaboro</label>

                <input type="radio" id="noColaboroB" name="colaborarBank" [value]="false" #noColaboroB [(ngModel)]="IsPlasticBank">
                <label for="noColaboroB">No colaboro</label>


              </div>
            </article>

            <div class="footerCambiarMundo">
              <p *ngIf="!dataSource[0].IsPlasticBank">*Colabora con el proyeto por solo {{dataSource[0].ImportePlasticBank}}€ al día</p>
              <p *ngIf="dataSource[0].IsPlasticBank"></p>
              <button class="btn btnStyle_1" style="float:right" (click)="guardarInfo('PlasticBank')" [disabled]="IsPlasticBank == dataSource[0].IsPlasticBank">Cambiar</button>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="acordeon">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div>
            <p><b>¿Quieres cambiar la contraseña de tu cuenta?</b></p>
            
          </div>
        </mat-panel-title>
        <mat-panel-description>
          Cambio de contraseña
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="contenedorCambios">
        <div class="cardCambioContrasena">
          <h2 class="tituloCards">Cambio contraseña</h2>
          <br>
          <form [formGroup]="cambioPass" (ngSubmit)="changePassword()" class="step-container">
            <section>
              <div>
                <label role="contrasenaActual">Contraseña actual</label>
                <input type="password" placeholder="Contraseña actual" id="contrasenaActual" formControlName="contrasenaActual"
                  [ngClass]="{'error-input': cambioPass.controls.contrasenaActual.touched && cambioPass.controls.contrasenaActual.invalid}">
                  <span class="formErrorIconControl" matTooltip="Contraseña Incorrecta" *ngIf="cambioPass.controls.contrasenaActual.touched && cambioPass.controls.contrasenaActual.invalid">
                    <i class="fal fa-exclamation-circle" style="color: red;"></i>
                  </span>
                  
                <span style="color:red;" *ngIf="cambioPass.controls.contrasenaActual.touched && cambioPass.controls.contrasenaActual.invalid">La contraseña no es correcta</span>
              </div>
      
              <br>
      
              <div>
                <label role="contrasenaNueva">Nueva contraseña</label>
                <input type="password" placeholder="Contraseña nueva" id="contrasenaNueva" formControlName="contrasenaNueva"
                  [ngClass]="{'error-input': cambioPass.controls.contrasenaNueva.touched && cambioPass.controls.contrasenaNueva.invalid}">
                  <span class="formErrorIconControl" matTooltip="Contraseña incorrecta: longitud mínima de caracteres" *ngIf="cambioPass.controls.contrasenaNueva.touched && cambioPass.controls.contrasenaNueva.invalid">
                    <i class="fal fa-exclamation-circle" style="color: red;"></i>
                  </span>
              </div>
      
              <br>
      
              <div>
                <label role="contrasenaNuevaD">Vuelve a escribir la contraseña</label>
                <input type="password" placeholder="Vuelve a escribir la contraseña" id="contrasenaNuevaD" formControlName="contrasenaNuevaD"
                  [ngClass]="{'error-input': cambioPass.controls.contrasenaNuevaD.touched && cambioPass.controls.contrasenaNuevaD.invalid}">
                  <span class="formErrorIconControl" matTooltip="Formato de la contraseña incorrecto" *ngIf="cambioPass.controls.contrasenaNuevaD.touched && cambioPass.controls.contrasenaNuevaD.invalid">
                    <i class="fal fa-exclamation-circle" style="color: red;"></i>
                  </span>
                
                <span style="color:red;" *ngIf="cambioPass.controls.contrasenaNuevaD.touched && cambioPass.controls.contrasenaNuevaD.invalid">Las contraseñas no coinciden</span>
              </div>
            </section>
      
            <br><br>
            
            <div style="float: right;margin-right: 10px;">
              <button class="btn btnStyle_2" [disabled]="!cambioPass.valid">Modificar <i class="fas fa-pencil"></i></button>
            </div>
          </form>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>