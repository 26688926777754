import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { LoginService } from '../../services/login/login.service';
import { environment } from '../../../environments/environment';
import { AlmacenamientoService } from 'src/app/services/almacenamiento/almacenamiento.service';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  urlApi: string;
  token: string;
  bodyApiToken: any;
  listadoContratos: any; // TODO: Cambiar por Contrato[]
  ultimosContratos: any; // TODO
  companyName: string;
  agrupacontratoscups = environment.agrupacontratoscups;
  
  get headers(): HttpHeaders {
    return new HttpHeaders()
    .set('Content-Type', 'application/json;charset=UTF-8')
    .set('Authorization', 'bKZViEZkbc/vv0DdOJPyRaBzZkbrO5pLlC6yM3eYC9jN0WFq/6T3j7e5JU/7UNgf7gWsyr5o/bSJgmUe3UsalBMUBCIc/SeqgBvcfozxh36dv2UMukd0FJCPHa15U8Hz7TtcUgAty4Hx4TV03EpijEIv0J/blRmA8tL2oo0HBN9Qe6W/WKDs2T+w67LmBk/1eq3RoTV+8QfFRw6ImNLKG+QNA1bVfeHJ0dhurnzHOritIZhKxqpX7o9K+u1ql7dKJjHtbQOe4MP4TxBDf22tkQ==')
  }

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService,
    private almnService:AlmacenamientoService
  ) {
    this.urlApi = this._globalService.getUrlApi();
    if(this.almnService.getValue("companyName")){
      this.companyName = this.almnService.getValue("companyName");
    }else{
      this.companyName=environment.companyName;
    }
  }

  getListadoContratos(idCliente, IdCups?): Observable<any> {
    this.refrescarApi();
    let body = {};
    if (this.almnService.getValue('isAdminFincas') === 'true') {
      body = { IdCliente: idCliente, IsAdministrador: true};
    } else {
      body = { IdCliente: idCliente, IsAdministrador: false};
    }
    if(IdCups != undefined && this.agrupacontratoscups){
      body['IdCUps'] = IdCups;
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosLista', body, this._globalService.getHttpHeaders());
    }

    if(this.agrupacontratoscups){
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosAgrupadosLista', body, this._globalService.getHttpHeaders());
    }else{
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosLista', body, this._globalService.getHttpHeaders());
    }

  }
  
  getListadoContratosElekluz(idCliente, IdCups?): Observable<any> {
    this.refrescarApi();
    let body = {};
    if (this.almnService.getValue('isAdminFincas') === 'true') {
      body = { IdCliente: idCliente, IsAdministrador: true};
    } else {
      body = { IdCliente: idCliente, IsAdministrador: false};
    }
    if(IdCups != undefined && this.agrupacontratoscups){
      body['IdCUps'] = IdCups;
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosListaElekluz', body, this._globalService.getHttpHeaders());
    }

    if(this.agrupacontratoscups){
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosAgrupadosLista', body, this._globalService.getHttpHeaders());
    }else{
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosListaElekluz', body, this._globalService.getHttpHeaders());
    }

  }



  getDatosCups(idCups): Observable<any> {
    this.refrescarApi();
    const body = { IdCups: idCups, IdCliente: this.almnService.getValue('idCliente'), isAdministrador: this.almnService.getValue('isAdminFincas') };
    return this.http.post<any>(this.urlApi + 'CUPS/GetDatosCUPSComprobarCliente', body, this._globalService.getHttpHeaders());
  }

  getDatosContrato(idContrato): Observable<any> {
    this.refrescarApi();
    const body = { IdContrato: idContrato };
    let apiCallCompany = 'Contrato/GetContratoComprobarCliente'; // default

    // Depende de la empresa se modifica la llamada
    switch (this.companyName) {
      case 'Eleia':
        apiCallCompany = 'Contrato/GetContrato_Eleia';
        break;
    }
    return this.http.post<any>(this.urlApi + apiCallCompany, body, this._globalService.getHttpHeaders());
  }

  getDatosCliente(idcliente): Observable<any> {
    this.refrescarApi();
    const body = { IdCliente: idcliente };
    return this.http.post<any>(this.urlApi + 'Cliente/GetDatosClienteComprobarCliente', body, this._globalService.getHttpHeaders());
  }

  getUltimosContratos(idAdministrador, fechaInicio, fechaFin): Observable<any> {
    this.refrescarApi();
    const body = { IdAdministrador: idAdministrador, FechaInicio: fechaInicio, FechaFin: fechaFin };
    return this.http.post<any>(this.urlApi + 'Resumen/UltimosContratos', body, this._globalService.getHttpHeaders());
  }

  getEstados(id): Observable<any> {
    this.refrescarApi();
    const body = { IdAdministrador: id };
    return this.http.post<any>(this.urlApi + 'Resumen/GetEstados', body, this._globalService.getHttpHeaders());
  }

  getAdminInfo(id, isAdministrador): Observable<any> {
    this.refrescarApi();
    const body = { Id: id, IsAdministrador: isAdministrador};
    return this.http.post<any>(this.urlApi + 'Resumen/GetInfoAdmin', body, this._globalService.getHttpHeaders());
  }

  refrescarApi(){
    if(this.urlApi != this._globalService.getUrlApi()){
      this.urlApi= this._globalService.getUrlApi();
    }
  }

  getCadenaCliente(id): Observable<any> {
    this.refrescarApi();
    const body = { IdCliente: id};
    return this.http.post<any>(this.urlApi + 'Contrato/GetCodigoPromocional', body, this._globalService.getHttpHeaders());
  }

  getWalletCliente(id): Observable<any> {
    this.refrescarApi();
    const body = { IdCliente: id};
    return this.http.post<any>(this.urlApi + 'Contrato/GetSaldoWallet', body, this._globalService.getHttpHeaders());
  }

  updateWalletCliente(id): Observable<any> {
    this.refrescarApi();
    const body = { IdCliente: id};
    return this.http.post<any>(this.urlApi + 'Contrato/UpdateSaldoWallet', body, this._globalService.getHttpHeaders());
  }

  getDescuentosOnline(data, fecha): Observable<any> {
    this.refrescarApi();
    const body = { 
      CodigoContrato: data.CodigoContrato,
      Entorno: data.EntornoBD
    };
    return this.http.post<any>(this.urlApi + 'Listado/IncidenciasTelefonicas_WeKiwi', body, this._globalService.getHttpHeaders());
  }
  
  getListadoProducto(entorno) {
    var token;

    if(entorno.toUpperCase() == 'LUZ') {
      token = 'bKZViEZkbc/vv0DdOJPyRQdU2YEM+iygFIK/zeQqzxs277Am+Z/DaYOXdcAI182ciVo9YYHZN7H0y97L8106t3p7AoHsjvqLstmfxpGwkepHZfRSgYKz6JBfE8lNtWvMPXorB2DFKyh5YqlYOeQ5RcdrG9wt/CKNJngnr7rx27XauU4EDvZcR/ydm9X8oa0Vl+gtV67hOsNCMADf1P473TTwHDYK+RvC8yFS1XsbSX+zIkbsFawgXxCCtieImt8n5uoHXrQ8JyqkStXBm9BFee4B6gpq6W8Jnjhwx1i07JVEpkBT9yskBkJZuKtpPHoB';
    } else {
      token = 'bKZViEZkbc/vv0DdOJPyRaBzZkbrO5pLlC6yM3eYC9jN0WFq/6T3j7e5JU/7UNgf7gWsyr5o/bSJgmUe3UsalBMUBCIc/SeqgBvcfozxh36dv2UMukd0FJCPHa15U8Hz7TtcUgAty4Hx4TV03EpijEIv0J/blRmA8tL2oo0HBN9Qe6W/WKDs2T+w67LmBk/1rM8uj3xo8m3gIVV5wf9GevNfRAtlZGRYocRYqRqH87Sa06bB/09/jPyBEXmBNtfpTBF2lNtTcfFKzOYO/L7VJg==';
    }
    
    const httpHeaders = new HttpHeaders({
      'Authorization': token
       
    });

    const options = {
      headers: httpHeaders,
      responseType: 'text'
    };

    const data = { 
      Promociones : ["Tarifa Fija", "PREPAGO MENSUAL", "TARIFA INDEX"],
      Entorno: entorno.toUpperCase() == 'LUZ' ? 1 : 2
    }

    var url = this.urlApi.includes('apiovwekiwitest') ? 'https://apipawekiwitest.sigeenergia.com' : 'https://apipawekiwi.sigeenergia.com';

    return this.http.post(url + '/api/v1/ContratoCola/ListadoProductosPromociones', data, options as any)
  }

  ListadoRangosRecarga(entorno, potenciaMax, mes, codigo) {

    var numMes = '';
    switch (mes) {
      case 'Mensual':
        numMes = '1';
        break;
      case 'Bimestral':
        numMes = '2';
        break;
      case 'Trimestral':
        numMes = '3';
        break;
      // case 'Cuatrimestral':
      //   numMes = '4';
      //   break;
      default:
        numMes = '2';
      break
    }

    var data = {
      Entorno: entorno,
      Potencia: 11, //parseFloat(potenciaMax),
      Meses: numMes,
      CodigoComunicacion: codigo
    }

    var url = this.urlApi.includes('apiovwekiwitest') ? 'https://apipawekiwitest.sigeenergia.com' : 'https://apipawekiwi.sigeenergia.com';

    return this.http.post(url + '/api/v1/Tarifa/ListadoRangosRecarga', data, { headers: this.headers})
  }

  recalcularDescuentos(dtos) {

    let tarifa = dtos.filter(x=> "1" == x.TipoCargoDescuento)[0];
    let online = dtos.filter(x=> "3" == x.TipoCargoDescuento)[0];
    let acierta = dtos.filter(x=> "2" == x.TipoCargoDescuento)[0] ;

    dtos[2] = tarifa != undefined ? tarifa : {"TipoCargoDescuento": "1","CargoDescuentoEnergia": 0};
    dtos[1] = online != undefined ? online : {"TipoCargoDescuento": "3","CargoDescuentoEnergia": 0};
    dtos[0] = acierta != undefined ? acierta : {"TipoCargoDescuento": "2","CargoDescuentoEnergia": 0};
    
    return dtos
  }

  getDescuentosTarifaGrupo(idTG,numPeriodicidad) {
    const body = {
      IdTarifaGrupo: idTG,
      IdPeriodicidad: numPeriodicidad,
    }

    return this.http.post(this.urlApi + 'Contrato/GetDescuentosTarifaGrupo', body, this._globalService.getHttpHeaders())
  }

  getPreciosContrato(codContrato, entorno) {
    const body = {
      CodigoContrato: parseInt(codContrato),
      Entorno: entorno
    }

    return this.http.post(this.urlApi + 'Contrato/GetPreciosContrato', body, this._globalService.getHttpHeaders())
  }

  getCalculoEstimacion(codContrato, entorno) {
    const body = {
      CodigoContrato: codContrato,
      Entorno: entorno
    }

    return this.http.post(this.urlApi + 'Calculadora/Estimacion', body, this._globalService.getHttpHeaders())
  }

  getCalculoPrevision(codContrato, entorno) {
    const body = {
      CodigoContrato: codContrato,
      Entorno: entorno
    }

    return this.http.post(this.urlApi + 'Calculadora/Prevision', body, this._globalService.getHttpHeaders())
  }

  getDescuentosHistorico(idContrato) {
    const body = {
    IdContrato: idContrato,
    }

    return this.http.post(this.urlApi + 'Contrato/GetHistoricoDescuentosContrato', body, this._globalService.getHttpHeaders())
  }

  getHistoricoLecturas(codigoContrato) {
    const body = {
      CodigoContrato: codigoContrato,
    }

    return this.http.post(this.urlApi + 'Contrato/GetHistoricoLecturas ', body, this._globalService.getHttpHeaders())
  }

  getDescargaContratoPDF(contrato): Observable<Blob> {
    var token;
    var idusuario;
    var idempresa;

    if(contrato.Entorno.toUpperCase() == 'LUZ') {
      token = 'bKZViEZkbc/vv0DdOJPyRaBzZkbrO5pLlC6yM3eYC9jN0WFq/6T3j7e5JU/7UNgf7gWsyr5o/bSJgmUe3UsalBMUBCIc/SeqgBvcfozxh36dv2UMukd0FJCPHa15U8Hz7TtcUgAty4Hx4TV03EpijEIv0J/blRmA8tL2oo0HBN9Qe6W/WKDs2T+w67LmBk/1eq3RoTV+8QfFRw6ImNLKG+QNA1bVfeHJ0dhurnzHOritIZhKxqpX7o9K+u1ql7dKJjHtbQOe4MP4TxBDf22tkQ==';
      idusuario = 3;
      idempresa = 1;
    } else {
      token = 'bKZViEZkbc/vv0DdOJPyRaBzZkbrO5pLlC6yM3eYC9jN0WFq/6T3j7e5JU/7UNgf7gWsyr5o/bSJgmUe3UsalBMUBCIc/SeqgBvcfozxh36dv2UMukd0FJCPHa15U8Hz7TtcUgAty4Hx4TV03EpijEIv0J/blRmA8tL2oo0HBN9Qe6W/WKDs2T+w67LmBk/1eq3RoTV+8QfFRw6ImNLKG+QNA1bVfeHJ0dhurnzHOritIZhKxqpX7o9K+u1ql7dKJjHtbQOe4MP4TxBDf22tkQ==';
      idusuario = 38;
      idempresa = 2;
    }

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
 
    var url = this.urlApi.includes('apiovwekiwitest') ? 'https://apipawekiwitest.sigeenergia.com' : 'https://apipawekiwi.sigeenergia.com';
    return this.http.get(url + '/api/v1/Contrato/GetIdContratoPDF/Empresa/' + idempresa + '/Usuario/' + idusuario + '/Contrato/' + contrato.IdContrato, {headers: httpHeaders,responseType: "blob"})

  }
}
