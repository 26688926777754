import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailService } from 'src/app/services/mail/mail.service';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';

@Component({
  selector: 'app-facturas-masivas-dialog',
  templateUrl: './facturas-masivas-dialog.component.html',
  styleUrls: ['./facturas-masivas-dialog.component.scss']
})
export class FacturasMasivasDialogComponent implements OnInit{

  // declarations
  entorno: string;

  form: FormGroup;

  IsAdministrador: boolean;
  IdCliente: number;
  Entorno: string;
  ListadoContratos: any;
  ListadoContratosTemporal: any;

  // mesesgraficocch = environment.mesesgraficocch;
  FecInicio: any = moment().subtract(1, 'M').format();
  FecFin: any = moment().format();
  FecInicioAux: any;
  FecFinAux: any = moment().format();
  selectedCups = new FormControl();

  cupsFiltered: string[] = [];
  cupsFilteredAux: string[] = [];
  arrayCupsSeleccionados: any = [];

  cif = new FormControl();
  importe = new FormControl();
  selectedtarifa = new FormControl();
  arrayTarifas: string[] = ["Ninguna","2.0A ML", "2.1A ML", "2.0DHA ML", "2.1DHA ML", "2.0TD",  "3.0A ML", "3.1A ML", , "3.0TD", "6.0A ML", "6.1A ML", "6.0TD"];

  ListadoIdsFacturas: any;
  IdsDocumento = [];
  IdsFacturaVentaCabecera = [];
  isLoading = false;

  MostrarDescargaMasivaFacturasBotonExcel: boolean;
  MostrarDescargaMasivaFacturasFiltroCif: boolean;
  MostrarDescargaMasivaFacturasFiltroImporte: boolean;
  MostrarDescargaMasivaFacturasFiltroTarifa: boolean;

  msgError = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private _facturasService: FacturasService,
    private _funcionesService: FuncionesService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<FacturasMasivasDialogComponent>){
    this.dialogRef.updateSize('100%','100%');

    this.IsAdministrador = this.data.IsAdministrador;
    this.IdCliente = this.data.IdCliente;
    this.Entorno = this.data.Entorno;
    this.ListadoContratos = this.data.ListadoContratos;
  }

  ngOnInit() {

    this.MostrarDescargaMasivaFacturasBotonExcel = environment.MostrarDescargaMasivaFacturasBotonExcel;
    this.MostrarDescargaMasivaFacturasFiltroCif = environment.MostrarDescargaMasivaFacturasFiltroCif;
    this.MostrarDescargaMasivaFacturasFiltroImporte = environment.MostrarDescargaMasivaFacturasFiltroImporte;
    this.MostrarDescargaMasivaFacturasFiltroTarifa = environment.MostrarDescargaMasivaFacturasFiltroTarifa;
    
    this.ListadoContratosTemporal = [];
    if (this.ListadoContratos.length > 0) {
      this.limpiarCupsRepetidos();
    }
  }

  // Limpiamos los cups repetidos en un solo array de cups unicos
  limpiarCupsRepetidos(){

    this.ListadoContratos.forEach(obj => {
      var flag = 0;

      this.ListadoContratosTemporal.forEach(objTemp=> {
          if(obj.Cups == objTemp.Cups){
            flag = 1;
          }
      });

      if(flag == 0){ this.ListadoContratosTemporal.push(obj); }
    });

    // console.log(this.ListadoContratos);
    // console.log(this.ListadoContratosTemporal);

    this.ListadoContratos = this.ListadoContratosTemporal;

    this.ListadoContratos.forEach(obj => { this.cupsFiltered.push(obj.Cups); });

    this.cupsFilteredAux = this.cupsFiltered;

  }


  search(query: string) {
    const result = this.select(query.toUpperCase());
    this.cupsFiltered = result;
    // if(this.cupsFiltered.length == 0) {this.cupsFiltered = this.cupsFilteredAux;}
  }

  select(query: string): string[] {
    const result: string[] = [];
    for (const a of this.cupsFilteredAux) {

      if (a.toUpperCase().indexOf(query) > -1) {
        result.push(a);
      }
    }
    return result;
  }

  selectAll(checkAll) {

    if (checkAll) {
      this.selectedCups.reset();
      // Desmarcar todos
      this.arrayCupsSeleccionados = [];
    } else {
      // Marcar todos
      this.selectedCups.setValue(this.cupsFiltered);
      for (const i of this.cupsFiltered) {
        this.arrayCupsSeleccionados.push(i);
      }
    }
  }

  BusquedaNuevoCups() {

    this.arrayCupsSeleccionados = [];

    for (const i of this.selectedCups.value) {

        this.arrayCupsSeleccionados.push(i);

    }
  }

  descargarFacturasLote(tipo){

    // console.log("this.selectedCups");
    // console.log(this.selectedCups.value);
    // console.log("this.arrayCupsSeleccionados");
    // console.log(this.arrayCupsSeleccionados);
    // console.log(moment(this.FecInicio).format('YYYY-MM-DD'));;
    // console.log(this.FecFin);

    this.isLoading = true;


    this._facturasService
        .getIdFacturasByCups(
          this.IdCliente, 
          this.IsAdministrador, 
          moment(this.FecInicio).format('YYYY-MM-DD'), 
          moment(this.FecFin).format('YYYY-MM-DD'), 
          this.arrayCupsSeleccionados,
          this.cif.value == null ? '' : this.cif.value,
          this.importe.value == null ? '' : this.importe.value,
          this.selectedtarifa.value == 'Ninguna' || this.selectedtarifa.value == null ? '' : this.selectedtarifa.value
          )
        .subscribe(
          data => {

            this.ListadoIdsFacturas = this._funcionesService.decodificarToken(data);
            // console.log(this.ListadoIdsFacturas);
            this.IdsDocumento = this.ListadoIdsFacturas.IdsDocumento;
            this.IdsFacturaVentaCabecera = this.ListadoIdsFacturas.IdsFacturaVentaCabecera;

            if(this.ListadoIdsFacturas.length < 1 || this.IdsDocumento.length < 1 || this.IdsFacturaVentaCabecera.length < 1){

              this.isLoading = false;
              this.msgError = "No se han encontrado facturas."
              this._snackBar.open(this.msgError, "Cambie las fechas", {
                duration: 2000,
              });

            } else {

              switch (tipo) {

                case 'zip':
                  // Descarga de archivo ZIP de facturas
                  this._facturasService.getDescargaFacturasZip(this.IdsDocumento).subscribe(
                    data => {
                      this.isLoading = false;
                      const zip = new Blob([data], { type: 'application/zip' });
                      const zipURL = URL.createObjectURL(zip);
                      window.open(zipURL);
                      this.msgError = "Descargando archivo comprimido..."
                      this._snackBar.open(this.msgError, "Espere", {
                        duration: 2000,
                      });
                    });
                  break;

                case 'excel':
                  // Descarga de archivos EXCEL de facturas
                  this._facturasService.getDesgloseFacturasExcel(this.IdsFacturaVentaCabecera, true).subscribe(
                    data => {
                      this.isLoading = false;
                      const excel = new Blob([data], { type: 'application/vnd.ms-excel' });
                      const urlExcel = window.URL.createObjectURL(excel);
                      window.open(urlExcel);
                      this.msgError = "Descargando facturas en Excel..."
                      this._snackBar.open(this.msgError, "Espere", {
                        duration: 2000,
                      });
                    });
                  break;
              }

            }

          },
          err => {
            this.isLoading = false;
            this.msgError = "La solicitud ha fallado."
              this._snackBar.open(this.msgError, "Cambie las fechas", {
                duration: 2000,
              });
          }
        );

  }

  CalculoSeleccionFechasInicio(FechasInicio){}

  CalculoSeleccionFechasFin(FechasFin){}

}