import { Component, ComponentFactoryResolver, HostListener, OnInit } from '@angular/core';
import { AlmacenamientoService } from './services/almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'OficinaVirtual';
  mostrarBoton = false;
  url: any = window.location.href.split('/')[window.location.href.split('/').length - 1];
  sliderOpen: any = window.innerWidth <= 900 ? 'false' : this.url == 'login' || this.url == 'recordarcontrasena' ? 'false' : 'true';
  sliderMode: any = window.innerWidth <= 900 ? 'over' : 'side';
  nombreCliente: any = this.almnService.getValue('nombreCliente');
  codigoCliente: any = this.almnService.getValue('idCliente');
  nuevoContratoEntorno: String = window.location.href.includes('clientes.wekiwi.es') ? 'https://calculadora.wekiwi.es/' : 'https://fawekiwitest.sigeenergia.com/'
  importeCliente :any = this.almnService.getValue('importeCliente');

  constructor(private almnService:AlmacenamientoService){}

  checkSlider(data) {
    this.mostrarBoton = data._animationState == 'open' ? false : true;
  }

  ngOnInit(): void {
    this.almnService.loadSesion();
    (window as any).showValues = () =>{
      this.almnService.showValues();
    }
  }

  @HostListener("window:beforeunload") unloadHandler() {
    this.almnService.saveSesion();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth <= 900) {
      this.sliderOpen = 'false';
      this.sliderMode = 'over';
    } else {
      this.sliderOpen = this.url == 'login' || this.url == 'recordarcontrasena' ? 'false' : 'true';
      this.sliderMode = 'side';
    }
  }

  @HostListener('window:load', ['$event'])
  onLoad(event) {
    console.log('APP CONTROLLER');
    // sessionStorage.clear();
    this.almnService.clear('soft');
    this.nombreCliente = this.almnService.getValue('nombreCliente');
    this.codigoCliente = this.almnService.getValue('idCliente');
    this.importeCliente = this.almnService.getValue('importeCliente');
  }

  changeOfRoutes() {
    this.url = window.location.href.split('/')[window.location.href.split('/').length - 1];
    if(this.url != 'login' && this.url != 'recordarcontrasena') {
      if(!Boolean(this.almnService.getValue('token'))){window.location.href = '/login'}
    }
    this.sliderOpen = this.url != 'login' && this.url != 'recordarcontrasena' ? 'true' : 'false';
    this.nombreCliente = this.almnService.getValue('nombreCliente');
    this.codigoCliente = this.almnService.getValue('idCliente');
    this.importeCliente = this.almnService.getValue('importeCliente');

  }

  cargarDesplegable(param) {
    
    const cbox = document.querySelectorAll(".menuItemActiveFather");

    if (param != -1) {
    
      cbox[param].nextElementSibling['style'].display = cbox[param].nextElementSibling['style'].display == 'block' ? 'none' : 'block';
     
      for (let i = 0; i < cbox.length; i++) {
        if(i != param) {
          cbox[i].nextElementSibling['style'].display = "none";
        }
      }

    } else {
      
      for (let i = 0; i < cbox.length; i++) {
          cbox[i].nextElementSibling['style'].display = "none";
      }
    }
  }

  cerrarSlider() {
    
    this.sliderOpen = 'false';
  }
}

setTimeout(() => {
  document.getElementById('cargarPortal1').style.display = 'none';
  document.getElementById('cargarPortal2').style.display = 'none';
}, 800);